import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  private language: BehaviorSubject<string>;
  private languagesAvailable = [
    'FR_fr',
    'EN_gb',
  ];

  constructor() {
    localStorage.setItem('currentLang', 'FR_fr');
    this.language = new BehaviorSubject<string>('FR_fr');
  }

  setLanguage(language: string): void {
    if (this.languagesAvailable.indexOf(language) > -1) {
      localStorage.setItem('currentLang', language);
      this.language.next(language);
    }
  }

  getLanguageValue(): string {
    return this.language.getValue();
  }

  getLanguage(): Observable<string> {
    return this.language.asObservable();
  }

  getLanguagesAvailable() {
    const tbr =  this.languagesAvailable.slice();
    tbr.splice(this.languagesAvailable.indexOf(this.getLanguageValue()), 1);
    return tbr;
  }
}
