import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule) },
  { path: 'calendrier', loadChildren: () => import('src/app/dive-calendar/dive-calendar.module').then(m => m.DiveCalendarModule) },
  { path: '**', component: PageNotFoundComponent }
  ];

export const routing = RouterModule.forChild(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
