import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { MomentModule } from 'ngx-moment';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
// import {JwtInterceptor} from './interceptors/jwt.interceptor';
// import {ErrorInterceptor} from './interceptors/error.interceptor';
// import { NewsRecentComponent } from './components/news-recent/news-recent.component';
// import { MatVideoModule } from 'mat-video';
import {PageNotFoundComponent} from '../page-not-found/page-not-found.component';
import {ImageSliderNoTitleComponent} from './components/image-slider-no-title/image-slider-no-title.component';

@NgModule({
  declarations: [
    // NewsRecentComponent,
    PageNotFoundComponent,
    ImageSliderNoTitleComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    HttpClientModule,
    MomentModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    Nl2BrPipeModule,
    MatMomentDateModule,
    MatInputModule,
    MatSidenavModule
    // MatVideoModule
  ],
  exports: [
    // cms components

    // imports
    RouterModule,
    FlexLayoutModule,
    HttpClientModule,
    MomentModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    Nl2BrPipeModule,
    MatMomentDateModule,
    MatInputModule,
    MatSidenavModule,
    ImageSliderNoTitleComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ] // TODO fix mat-video and remove
})
export class SharedModule { }
