import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { trigger, state, style, animate, transition, query, stagger, animateChild, keyframes } from '@angular/animations';
import {TranslateService} from '../../services/translate.service';

@Component({
  selector: 'app-image-slider-no-title',
  templateUrl: './image-slider-no-title.component.html',
  styleUrls: ['./image-slider-no-title.component.scss'],
  animations: [
    trigger('fade', [
      state('1', style({
        opacity: 1
      })),
      state('0', style({
        opacity: 0
      })),
      transition('* => *', [animate(`1s cubic-bezier(.17,.67,.27,1.26)`)])
    ])
  ]
})
export class ImageSliderNoTitleComponent implements OnInit {

  bgUrls: string[];
  nbImages: number;
  @Input() set setbgImages(bgImages) {
    this.bgUrls = bgImages;
    // for(let image in bgImages)
    //   this.bgUrls.push('url(\'../../../../' + bgImages[image] + '\')')
    this.nbImages = this.bgUrls.length;
  }
  index = 0;

  @Input() data;

  interval;
  sliderPeriod = 5;
  timeLeft: number = this.sliderPeriod;

  language: string;

  constructor(private translateService: TranslateService) {
    this.translateService.getLanguage().subscribe(value => {
      this.language = value;
    });
    this.language = this.translateService.getLanguageValue();
  }

  ngOnInit() {
    this.startTimer();
  }

  startTimer() {
    this.timeLeft = this.sliderPeriod;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = this.sliderPeriod;
        this.nextSlide();
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  nextSlide() {
    if (this.index < this.nbImages - 1) {
      this.index++;
    } else {
      this.index = 0;
    }
  }

  previousSlide() {
    if (this.index > 0) {
      this.index--;
    } else {
      this.index = this.nbImages - 1;
    }
  }

}
